import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Title, Text, Box, ButtonIcon } from '../../components/Core'
import PageWrapper from '../../components/PageWrapper'
import Screenshot from '../../components/Screenshot'
import NextLink from '../../components/NextLink'
import WebsiteLink from '../../components/WebsiteLink'
import Slides from '../../components/Slides'
import Contact from '../../sections/common/Contact'
import Hero from '../../sections/common/Hero'
import { Keys, Key } from '../../sections/keys/Keys'

import favicon from '../../assets/images/aa/favicon.png'
import devices from '../../assets/images/aa/device-screens.jpg'
import analytics from '../../assets/images/aa/analytics.png'

import portal from '../../assets/images/aa/portal.jpg'
import login from '../../assets/images/aa/login.jpg'
import liveeng from '../../assets/images/aa/live-engagement.jpg'

const WorkSingle = () => {
    return (
        <>
            <PageWrapper>
                <Hero className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="8">
                                <Text variant="tag">FULL STACK</Text>
                                <Title mb="0.5em">Accountable Ads</Title>
                                <Text variant="p" mb="1.6em">
                                    The mission statement of our company was to
                                    provide accountable advertising. That's
                                    where this project started and the reason it
                                    was never finished, not officially anyways.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Accountable Ads stack started over a decade
                                    ago when I built our first few tracking
                                    scripts. This was at a time before google
                                    analytics when it was known as urchine.
                                    That's where the utc_source tags in urls
                                    originated from. The stack evolved several
                                    times over the past decade
                                </Text>
                            </Col>
                            <Col lg="4">
                                <img
                                    src={analytics}
                                    alt="Website Analytics"
                                    className="img-fluid w-100"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Hero>

                <div className="mt-lg-3">
                    <Container>
                        <Row>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Client</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Novatex
                                </Title>
                            </Col>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Time</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Early 2000
                                </Title>
                            </Col>
                            <Col lg="4">
                                <Text variant="tag">Website</Text>

                                <Title variant="cardBig" className="mt-3">
                                    <WebsiteLink
                                        src={favicon}
                                        href="https://www.accountableads.com"
                                    >
                                        accountableads.com
                                    </WebsiteLink>
                                </Title>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Section className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="5" className="mb-5 pr-lg-5">
                                <img
                                    src={devices}
                                    alt="Device Screens"
                                    className="img-fluid w-100"
                                />
                            </Col>
                            <Col lg="7" className="mb-5 pl-lg-5">
                                <Title variant="cardBig" className="mb-4">
                                    Origins in Marketing Accountablility
                                </Title>
                                <Text variant="p" mb="1.6em">
                                    The first marketing dashboard I created was
                                    for a company called Laser Spine Insitute.
                                    LSI was spending over 10k a day in marketing
                                    with google adwords without any idea what
                                    ads the visitors were coming to the website
                                    with.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Our company at the time was tasked with
                                    running the website and optimizing the
                                    website for search engines. Back then SEO
                                    was considered taboo because rankings were
                                    based on inbound link count. Everyone was
                                    selling link farming, but we saw the future
                                    was in accountable paid advertising.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    That's when I built a central tracking
                                    system that would collect the advertising
                                    tags and submit them to CRMs along with the
                                    visitors information.
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Keys mt="-5%">
                    <Key name="mysql" title="01. MySQL Database" left>
                        Tracking website visitors with marketing required
                        massive amounts of storage. Being a budget conscious
                        developer I always leaned towards open source. Decades
                        ago MySQL was really the only free option at a time when
                        companies were charging obscene amounts for licening
                        other big data solutions.
                    </Key>

                    <Key name="google" title="02. Google Analytics">
                        As an early adapter of Google Analytics I firmly
                        believed it was the future of web analytics because of
                        the importance of understanding how money was being
                        spent on advertising. Goals, Devices and Interactions on
                        all the websites I built were bring analyzed to improve
                        marketing accountablility.
                    </Key>

                    <Key name="salesforce" title="03. Salesforce" left>
                        The Accountable Ads ability to update our clients CRM
                        with advertising metrics along side leads generated was
                        game changing. Getting leads from a website was only the
                        first step, now I was able to generate reports on a
                        complete picture of marketing programs right to sale
                        completion. Lower spend in bad performing ads, increase
                        spend in ads that produce sales.
                    </Key>

                    <Key name="twilio" title="04. Twilio">
                        I remember how excited I was for twilio to come to
                        market. At the time, It was the only service available
                        that could answer incoming calls and connect with users
                        in salesforce through a webhook. I developed one of the
                        first telephony systems in salesforce console that could
                        connect to a unique phone number. It was then possible
                        to link incoming ad campaign phone numbers to
                        salesforce.
                    </Key>
                </Keys>

                <Section mt="22%" bg="dark">
                    <Container>
                        <Row
                            css={`
                                margin-top: -35%;
                            `}
                        >
                            <Col xs="12" className="mb-5">
                                <Slides>
                                    <Screenshot
                                        src={portal}
                                        alt="Accountable Ads Portal"
                                    />
                                    <Screenshot
                                        src={login}
                                        alt="Accountable Ads Login"
                                    />
                                    <Screenshot
                                        src={liveeng}
                                        alt="Accountable Ads Live Engagement"
                                    />
                                </Slides>
                            </Col>
                        </Row>

                        <div className="text-center mt-lg-5">
                            <Text
                                variant="tag"
                                className="mb-1"
                                color="lightShade"
                            >
                                Next Project
                            </Text>
                            <NextLink to="/works/collective-care/">
                                Collective Care
                            </NextLink>
                        </div>
                    </Container>
                </Section>

                <Box py={4}>
                    <Contact />
                </Box>
            </PageWrapper>
        </>
    )
}
export default WorkSingle
